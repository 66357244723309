<template>
  <div class="home" style="background:#f5f5f5;">
    <b-navbar toggleable="lg" variant="white" class="shadow d-none">
      <b-navbar-brand href="#"
        ><b-icon-pencil-square></b-icon-pencil-square> Form
        Registration</b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    </b-navbar>
    <b-container class="pt-3">
      <b-row>
        <b-col lg="8" offset-lg="2">
          <b-row>
            <b-col lg="12"> </b-col>
            <b-col lg="12" class="shadow mb-3 rounded pt-3 bg-white">
              <h5>Form Registrasi</h5>
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form
                  class="text-left mt-3"
                  method="post"
                  @submit.prevent="handleSubmit(submitForm)"
                >
                  <validation-provider
                    name="Nomor Telpon"
                    :rules="{
                      required: true,
                      min: 10,
                      regex: /^62[\d]{11,13}$/,
                    }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description="Eg:62811111111"
                      label="Nomor Telepon"
                      label-for="phone-number"
                    >
                      <b-form-input
                        id="phone-number"
                        type="text"
                        v-model="form.phone"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-phone"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-phone">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Alamat Email"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Alamat Email"
                      label-for="input-horizontal"
                    >
                      <b-form-input
                        id="email"
                        type="email"
                        v-model="form.email"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-email"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-email">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Nama"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Nama"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        type="text"
                        v-model="form.name"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-name"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-name">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Jenis Kelamin"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Jenis Kelamin"
                      label-for="input-horizontal"
                    >
                      <b-form-radio-group
                        v-model="form.gender"
                        :options="['Laki-laki', 'Perempuan']"
                        name="gender"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-gender"
                        required
                      ></b-form-radio-group>
                      <b-form-invalid-feedback id="input-gender">{{
                        errors.gender
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Kewarganegaraan"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Kewarganegaraan"
                      label-for="citizen"
                    >
                      <b-form-radio-group
                        v-model="form.citizen"
                        :options="['WNI', 'WNA']"
                        name="citizen"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-citizen"
                        required
                      ></b-form-radio-group>
                      <b-form-invalid-feedback id="input-citizen">{{
                        errors.citizen
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Negara Saat Ini Tinggal"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Negara Saat Ini Tinggal"
                      label-for="country"
                    >
                      <v-select
                        :options="dataCountry"
                        :reduce="(dataCountry) => dataCountry.label"
                        v-model="form.countryLive"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!form.countryLive"
                            v-bind="attributes"
                            v-on="events"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-country"
                          />
                        </template>
                      </v-select>
                      <b-form-invalid-feedback id="input-country">{{
                        errors.countryLive
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Provinsi"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                    v-if="form.countryLive == 'Indonesia'"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Provinsi"
                      label-for="province"
                    >
                      <v-select
                        :options="dataProvince"
                        v-model="form.province"
                        label="name"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!form.province"
                            v-bind="attributes"
                            v-on="events"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-province"
                          />
                        </template>
                      </v-select>
                      <b-form-invalid-feedback id="input-province">{{
                        errors.province
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Alamat Tempat Tinggal Saat Ini"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Alamat Tempat Tinggal Saat Ini"
                      label-for="input-horizontal"
                    >
                      <b-textarea
                        v-model="form.address"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-address"
                      ></b-textarea>
                      <b-form-invalid-feedback id="input-address">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    name="Tempat Lahir Saat Ini"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="fieldset-horizontal"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="7"
                      description=""
                      label="Tempat Lahir"
                      label-for="birth-place"
                    >
                      <b-form-input
                        type="text"
                        v-model="form.bornPlace"
                        id="birth-place"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-born-place"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-born-place">{{
                        validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <b-form-group
                    id="fieldset-horizontal"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    description=""
                    label="Tanggal Lahir"
                    label-for="born-date"
                  >
                    <date-picker
                      v-model="bornDate"
                      :format="'YYYY-MM-DD'"
                      class="w-100"
                      id="input-born-place"
                      aria-describedby="input-born-place"
                    ></date-picker>
                    <span class="text-danger">{{ errors.bornDate }}</span>
                  </b-form-group>
                  <b-form-group
                    id="fieldset-horizontal"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    description=""
                    label="Alamat Twitter"
                    label-for="twitter"
                  >
                    <b-form-input
                      id="input-horizontal"
                      type="text"
                      v-model="form.socmed.twitter"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="fieldset-horizontal"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    description=""
                    label="Alamat Instagram"
                    label-for="input-horizontal"
                  >
                    <b-form-input
                      id="input-horizontal"
                      type="text"
                      v-model="form.socmed.instagram"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="fieldset-horizontal"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    description=""
                    label="Alamat Tiktok"
                    label-for="input-horizontal"
                  >
                    <b-form-input
                      id="input-horizontal"
                      type="text"
                      v-model="form.socmed.tiktok"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="fieldset-horizontal"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    description=""
                    label="Alamat Linkedin"
                    label-for="linkedin"
                  >
                    <b-form-input
                      id="linkedin"
                      type="text"
                      v-model="form.socmed.linkedin"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                    class="text-right"
                  >
                    <b-btn
                      type="submit"
                      variant="success"
                      class="w-25"
                      v-if="!loader"
                      >Submit</b-btn
                    >
                    <b-btn
                      type="submit"
                      variant="success"
                      class="w-25"
                      v-if="loader"
                      :disabled="loader"
                      ><b-spinner
                        variant="dark"
                        label="Spinning"
                        small
                      ></b-spinner
                    ></b-btn>
                  </b-form-group>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { BIconPencilSquare } from "bootstrap-vue";
export default {
  name: "Home",
  components: {
    vSelect,
    DatePicker,
    BIconPencilSquare,
  },
  mounted: function() {
    this.fetchCountryData();
    this.fetchProvinceData();
  },
  data() {
    return {
      apiUrl: "https://be-heureuy-batian.herokuapp.com/",
      provinceUrl:
        "https://api-trimitra-65q45htc.de.gateway.dev/geo-area/province?key=AIzaSyCq1gZtxWAwBqhXlzcCto2zU3BiA2KGke8",
      form: {
        phone: "",
        email: "",
        name: "",
        gender: "",
        citizen: "WNI",
        countryLive: "Indonesia",
        province: "",
        address: "",
        bornPlace: "",
        bornDate: "",
        socmed: {
          twitter: "",
          instagram: "",
          tiktok: "",
          linkedin: "",
        },
      },
      errors: {
        bornDate: "",
        countryLive: "",
        gender: "",
        citizen: "",
      },
      bornDate: "",
      loader: false,
      dataCountry: [],
      dataProvince: [],
    };
  },
  watch: {
    bornDate: function(value) {
      if (value) this.errors.bornDate = "";
    },
  },
  methods: {
    fetchCountryData() {
      this.$axios.get(this.apiUrl + "dictionary/country").then((response) => {
        if (response.status == 200) {
          const tmpData = response.data.data;
          this.dataCountry = tmpData.map((item) => {
            return { label: item.name, code: item.code };
          });
        }
      });
    },
    fetchProvinceData() {
      this.$axios.get(this.provinceUrl).then((response) => {
        if (response.status == 200) {
          this.dataProvince = response.data.data;
        }
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    makeToast(title = "Notifikasi", variant = "success", text = "") {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    dateToString() {
      return (
        this.bornDate.getFullYear() +
        "" +
        (parseInt(this.bornDate.getMonth()) > 10
          ? this.bornDate.getMonth()
          : "0" + this.bornDate.getMonth()) +
        "" +
        this.bornDate.getDate()
      );
    },
    setErrorsEmpty() {
      const validate = Object.keys(this.errors);
      for (let index = 0; index < validate.length; index++) {
        this.errors[validate[index]] = "";
      }
    },
    async submitForm() {
      this.setErrorsEmpty();
      if (this.bornDate === "") {
        this.errors.bornDate = "Tanggal Lahir Harus Diisi";
        return false;
      }
      this.form.bornDate = this.dateToString();
      this.loader = true;
      const req = await this.$axios.post(
        this.apiUrl + "prelogin/register",
        this.form
      );
      if (req.data.code == 200)
        this.makeToast("Berhasil", "success", req.data.message);
      else this.makeToast("Gagal", "danger", req.data.message);
      this.loader = false;
    },
  },
};
</script>
